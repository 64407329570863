
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.goods-info {
    display: flex;
    align-items: center;
    .good-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        cursor: pointer;
    }
}
